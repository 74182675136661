@import '../../../../theme/img.scss';
@import '../../../../theme/variable.scss';
.list-wrapper{
  background: $map_bg;
  background-color: #F8F9FB;
  background-size: 793px 870px;
  background-position: right bottom;
  padding: 72px 0;
  .content{
    width: $content-width;
    text-align: center;
    margin: 0 auto;
    .title{
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #123049;
      line-height: 56px;
      text-align: center;
    }
    .hit{
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #123049;
      line-height: 32px;
      padding-top: 30px;
    }
    .list{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .item{
        flex: 0 0 31%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 59px;
        .icon{
          width: 117px;
          height: 86px;
        }
        &:nth-child(1){
          .icon{
            position: relative;
            i{
              width: 117px;
              height: 86px;
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 2;
              background: $OH1_bg;
            }
            &::before{
              content: '';
              display: block;
              position: absolute;
              bottom: 0px;
              right: 14px;
              width: 38px;
              height: 44px;
              background: $OH1_bg_1;
              z-index: 10;
              
            }
            &:hover{
              &::before{
                animation: zhuandong1_1 1s linear;
              }
            }
          }
        }
        &:nth-child(2){
          .icon{
            position: relative;
            i{
              width: 117px;
              height: 86px;
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 2;
              background: $OH2_bg;
            }
            &::before{
              content: '';
              display: block;
              position: absolute;
              top: 49px;
              right: 0;
              width: 40px;
              height: 10px;
              background: $OH2_bg_1;
              z-index: 10;
            }
            &::after{
              content: '';
              display: block;
              position: absolute;
              bottom: 0px;
              right: 8px;
              width: 40px;
              height: 22px;
              background: $OH2_bg_2;
              z-index: 10;
            }
            &:hover{
              &::before{
                animation: zhuandong2_1 1s linear;
              }
              &::after{
                animation: zhuandong2_2 1s linear;
              }
            }
          }
        }
        &:nth-child(3){
          .icon{
            position: relative;
            i{
              width: 117px;
              height: 86px;
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 2;
              background: $OH3_bg;
            }
            &::before{
              content: '';
              display: block;
              position: absolute;
              top: 5px;
              left: 15px;
              width: 77px;
              height: 47px;
              background: $OH3_bg_1;
             
            }
            &::after{
              content: '';
              display: block;
              position: absolute;
              top: 0px;
              left: 5px;
              width: 80px;
              height: 36px;
              background: $OH3_bg_2;
              
            }
            &:hover{
              &::before{
                animation: zhuandong 0.1s linear;
              }
              &::after{
                animation: zhuandong1 0.5s linear;
              }
            }
          }
        }
        &:nth-child(4){
          .icon{
            position: relative;
            i{
              width: 117px;
              height: 86px;
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 2;
              background: $OH4_bg;
            }
            &::before{
              content: '';
              display: block;
              position: absolute;
              bottom: 10px;
              right: 0;
              width: 57px;
              height: 79px;
              background: $OH4_bg_1;
              transform: rotate(180deg);
            }
            &:hover{
              &::before{
                animation: zhuandong3 1s linear;
              }
            }
          }
        }
        &:nth-child(5){
          .icon{
            position: relative;
            i{
              width: 117px;
              height: 86px;
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 2;
              background: $OH5_bg;

            }
            &::before{
              content: '';
              display: block;
              position: absolute;
              bottom: 10px;
              right: 0;
              width: 47px;
              height: 54px;
              background: $OH5_bg_1;
              z-index: 3;
            }
            &:hover{
              &::before{
                animation: zhuandong5_1 1s linear;
              }
            }
          }
        }
        &:nth-child(6){
          .icon{
            position: relative;
            i{
              width: 117px;
              height: 86px;
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 2;
              background: $OH6_bg;

            }
            &::before{
              content: '';
              display: block;
              position: absolute;
              top: 39px;
              right: 22px;
              width: 17px;
              height: 27px;
              background: $OH6_bg_2;
              z-index: 3;
            }
            &::after{
              content: '';
              display: block;
              position: absolute;
              top: 39px;
              right: 46px;
              width: 17px;
              height: 27px;
              background: $OH6_bg_1;
              z-index: 3;
            }
            &:hover{
              &::before{
                animation: zhuandong6_1 1s linear;
              }
              &::after{
                animation: zhuandong6_2 1s linear;
              }
            }
          }
        }

        .item-title{
          font-size: 36px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #123049;
          line-height: 50px;
          padding:  24px 0 17px;
          text-align: center;
        }
        .item-hit{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #123049;
          line-height: 28px;
          width: 231px;
          text-align: center;
        }
      }
    }
  }

}

@keyframes zhuandong{
  0%{-webkit-transform:rotate(-45deg);}
  100%{-webkit-transform:rotate(0deg);}
}
@keyframes zhuandong1{
  0%{-webkit-transform:rotate(-65deg);}
  100%{-webkit-transform:rotate(0deg);}
}

@keyframes zhuandong3{
  0%{-webkit-transform:rotate(180deg);}
  33%{-webkit-transform:rotate(200deg);}
  66%{-webkit-transform:rotate(160deg);}
  100%{-webkit-transform:rotate(180deg);}
}

@keyframes zhuandong1_1{
  0%{-webkit-transform:rotate(-45deg);}
  33%{-webkit-transform:rotate(-55deg);}
  66%{-webkit-transform:rotate(-45deg);}
  100%{-webkit-transform:rotate(0);}
}
@keyframes zhuandong2_1{
  0%{right: -20px;}
  100%{right: 0;}
}
@keyframes zhuandong2_2{
  0%{right: 28px;}
  100%{right: 8px;}
}
@keyframes zhuandong5_1{
  0%{transform: scale(1);}
  0%{transform: scale(1.2);}
  100%{transform: scale(1);}
}

@keyframes zhuandong6_1{
  0%{right: 12px;}
  100%{right: 22px;}
}

@keyframes zhuandong6_2{
  0%{right: 56px;}
  100%{right: 46px;}
}







