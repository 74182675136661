@import '../../theme/img.scss';
@import '../../theme/variable.scss';
.footer{
  padding: 40px 412px;
  background: #171717;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .footer-txt{
    font-size: 14px;
    color: #878787;
    line-height: 17px;
    width: 100vw;
    font-size: 14px;
    font-family: Helvetica;
    .footer-box{
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      padding-bottom: 15px;
      div{
        padding-right: 32px;
      }
      img{
        flex:0 0 20px;
        height: 20px;
      }
    }
  }
}