@import '../../../../theme/img.scss';
@import '../../../../theme/variable.scss';
.comp-wrapper{
  position: relative;
  img{
    width: 100%;
    height: auto;
  }
  .content{
    position: absolute;
    width: $content-width;
    margin: 0 auto;
    text-align: left;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    .title{
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 56px;
    }
    .hit{
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 32px;
      padding: 30px 0 100px;
      width: 825px;
    }
    .icon{
      background: $right-arrow;
      width: 42px;
      height: 26px;
      position: relative;
      cursor: pointer;
      left: 0;
      animation: zhuandong 1s linear infinite;
      animation-play-state: paused;

      &:hover{
        
        animation-play-state: running;
      }
    }
  }

}

@keyframes zhuandong{
  0%{left: 0;}
  33%{left: 15px;}
  66%{left: 7px;}
  100%{left: 0px;}
}