@import '../../theme/img.scss';
@import '../../theme/variable.scss';
.app-bar{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 10;
  .content{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 99px;
    .logo{
      flex: 0 0 140px;
      height: 26px;
      background: $logo_img;
    }
    .bar-list{
      display: flex;
      align-items: center;
      .bar-item{
        font-size: 14px;
        color: #123049;
        line-height: 20px;
        cursor: pointer;
        &:not(:last-child){
          padding-right: 82px;
        }
        &.select{
          font-weight: 600;
          color: #009B93;
          position: relative;
          &::before{
            content: '';
            position: absolute;
            top: -10px;
            left: -24px;
            background: $icon_circle;
            width: 24px;
            height: 25.41px;
            display: inline-block;
            vertical-align: text-top;
          }
        }
      }
    }
  }

}