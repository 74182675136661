@import '../../theme/img.scss';
@import '../../theme/variable.scss';
.banner-wrapper{
  position: relative;
  img{
    width: 100%;
    height: auto;
  }
  .content-wrapper{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    .title{
      font-size: 100px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 140px;
      font-family: PingFangSC-Semibold, PingFang SC;
    }
    .hit{
      font-size: 24px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 33px;
      padding-top: 20px;
    }
  }
}