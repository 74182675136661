@import '../../theme/img.scss';
@import '../../theme/variable.scss';
.bar-list{
  display: flex;
  align-items: center;
  .bar-item{
    font-size: 14px;
    color: #123049;
    line-height: 20px;
    cursor: pointer;
    &:not(:last-child){
      padding-right: 82px;
    }
    &.select{
      font-weight: 600;
      color: #009B93;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        top: -10px;
        left: -24px;
        background: $icon_circle;
        width: 24px;
        height: 25.41px;
        display: inline-block;
        vertical-align: text-top;
      }
    }
  }
}