@import '../../theme/img.scss';
@import '../../theme/variable.scss';
.page-wrapper{
  .info{
    width: $content-width;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 81px 0 100px;
    text-align: left;
    .info-title{
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #123049;
      line-height: 56px;
    }
    .info-hit{
      padding-top: 32px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8F98A3;
      line-height: 24px;
      .info-hit-item{
        padding-bottom: 20px;
      }
    }
  }
}