@import '../../../../theme/img.scss';
@import '../../../../theme/variable.scss';
.comp-wrapper{
  position: relative;
  height: 870px;
  background: #fff;
  .content{
    display: flex;
    flex-direction: column;
    position: absolute;
    text-align: center;
    width: $content-width;
    transform: translateY(-50%);
    left: calc((100vw - 1200px)/2);
    top: 50%;
    width: 420px;
    z-index: 11;
    .info{
      width: 420px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      .title{
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #123049;
        line-height: 36px;
      }
      .hit{
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #123049;
        padding: 40px 0 30px;
      }
      .hit1{
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8F98A3;
        line-height: 24px;
        padding-bottom: 58px;
        text-align: center;
      }
      .btn{
        width: 120px;
        height: 48px;
        background: #21948C;
        border-radius: 8px;
        font-size: 16px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

  }
  .label-wrapper{
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 140px;
    right: 460px;
    z-index: 11;
    .label-item{
      flex: 1;
      display: flex;
      align-items: center;
      padding-right: 40px;
      span{
        color: #93A0AB;
        font-style: 14px;
        margin-left: 5px;
        flex: 0 0 auto;
      }
      &:nth-child(1){
        i{
          background: $icon_po;
          flex:0 0 20px;
          height: 24px;
        }
      }
      &:nth-child(2){
        i{
          background: #94D5D1;
          flex: 0 0 12px;
          height: 12px;
          border-radius: 50%;
        }
      }
      &:nth-child(3){
        i{
          background: #C9D0D5;
          flex: 0 0 12px;
          height: 12px;
          border-radius: 50%;
        }
      }
    }
  }

}